<template>
    <div style="width: 100vw;height: 100vh;background-color: #0f448C;overflow-y: auto;font-family: GravityNormal">
        <div class="accountform">
            <div style="display: flex;width: 100%;align-items: flex-start;">
                <div style="display: flex;flex: 1;align-items: flex-start;">
                    <img src="../../assets/image/logo.png" style="width: 240px;height: auto; margin-right: 32px" />
                    <img src="../../assets/image/wec_logo.png" style="width: 220px;height: auto;object-fit: cover;" />
                </div>
                <p style="width: 300px;font-size: 12px;text-align: right;color: #666;line-height: 20px;">
                    Extracurricular Activity Excellence (EAE) International stands as a global partner to the World Economics Cup (WEC), entrusted with the role of its operation.</p>
            </div>
            
            

            <div class="title1">Organization Registration for {{form.project_code}}</div>
            <div class="title1" style="font-size: 12px;margin-top: 5px;">please fill out this form in English</div>
            <div style="color: #ff0000; font-size: 14px; margin: 20px 0; text-align: center; width: 100%; padding: 10px; background-color: #ffe6e6; border-radius: 4px;">
                This registration form is only for ADVISERS/TEACHERS to register for their organizations. <br>If you are <strong>an individual STUDENT or a STUDENT team leader</strong>, please use the<a href="/#/Form/wec" style="color: #ff0000; text-decoration: underline;"> Student Registration Form</a> instead.
            </div>
            
            <el-form :model="form" :rules="formRules" ref="form"
                style="width: 100%;padding: 0px 20px;box-sizing: border-box;" label-position="top">

                <p style="text-align: left;color: #D3A12A; font-size: 16px; font-weight: bold;margin: 20px 0px;">
                    Registrant Information</p>

                <div class="row">
                    <div class="item">
                        <el-form-item>
                            <el-form-item label="First Name (in English)" prop="contact_givenName">
                                <el-input v-model="form.contact_givenName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <el-form-item label="Last Name (in English)" prop="contact_lastName">
                                <el-input v-model="form.contact_lastName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-form-item>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <el-form-item>
                            <el-form-item label="Position" prop="contact_position">
                                <el-input v-model="form.contact_position" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <el-form-item label="Email" prop="contact_email">
                                <el-input v-model="form.contact_email" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-form-item>
                    </div>
                </div>


                <div class="row">
                    <div class="item">
                        <el-form-item label="Gender" prop="contact_prefix">
                            <el-select v-model="form.contact_prefix" style="width: 100%;">
                                <el-option value="Male" label="Male"></el-option>
                                <el-option value="Female" label="Female"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="How do you learn about us" prop="know_sources">
                            <el-select v-model="form.know_sources" style="width: 100%;">
                                <el-option value="Previous projects" label="Previous projects"></el-option>
                                <el-option value="Recommendation by others"
                                    label="Recommendation by others"></el-option>
                                <el-option value="Student inquiries" label="Student inquiries"></el-option>
                                <el-option value="Web browsing" label="Web browsing"></el-option>
                                <el-option value="Search results" label="Search results"></el-option>
                                <el-option value="Proactive email" label="Proactive email"></el-option>
                                <el-option value="Others" label="Others"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <p style="text-align: left;color: #D3A12A; font-size: 16px; font-weight: bold;margin: 20px 0px;">
                    Organization Information</p>
                <div class="row">
                    <div class="item">
                        <el-form-item label="Organization Name" prop="account_nameEn">
                            <el-input v-model="form.account_nameEn" placeholder="Please input"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="Organization Type" prop="organization_type">
                            <el-select v-model="form.organization_type" style="width: 100%;">
                                <el-option value="Secondary School" label="Secondary School"></el-option>
                                <el-option value="Study Abroad Agency" label="Study Abroad Agency"></el-option>
                                <el-option value="Admission Consultant/Firm"
                                    label="Admission Consultant/Firm"></el-option>
                                <el-option value="Academic Enrichment Center"
                                    label="Academic Enrichment Center"></el-option>
                                <el-option value="Other" label="Other"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <div class="row" v-if="form.organization_type == 'Secondary School'">
                    <div class="item">
                        <el-form-item label="Curriculum type" prop="curriculum_types">
                            <div style="display: flex;">
                                <el-select v-model="form.curriculum_types" multiple="true">
                                    <el-option value="AP" label="AP"></el-option>
                                    <el-option value="BC/OSSD" label="BC/OSSD"></el-option>
                                    <el-option value="Blended Learning Center"
                                        label="Blended Learning Center"></el-option>
                                    <el-option value="IBDP" label="IBDP"></el-option>
                                    <el-option value="IGCSE or Alevel" label="IGCSE or Alevel"></el-option>
                                    <el-option value="PGA" label="PGA"></el-option>
                                    <el-option value="General high school" label="General high school"></el-option>
                                    <el-option value="Others" label="Others"></el-option>
                                </el-select>
                                <el-input v-if="checkShowOthersView()" v-model="form.other_curriculum_type" placeholder="Please input"
                                    style="width: 50%"></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="item">
                        <el-form-item label="Annual Tuition" prop="annual_tuition">
                            <el-select v-model="form.annual_tuition" placeholder="Please select" style="width: 100%;">
                                <el-option value="> 11,000 USD">> 11,000 USD</el-option>
                                <el-option value="> 6,000 USD">> 6,000 USD</el-option>
                                <el-option value="> 3,000 USD">> 3,000 USD</el-option>
                                <el-option value="> 1,500 USD">> 1,500 USD</el-option>
                                <el-option value="> 1,100 USD">> 1,100 USD</el-option>
                                <el-option value="> 750 USD">> 750 USD</el-option>
                                <el-option value="> 350 USD">> 350 USD</el-option>
                                <el-option value="< 350 USD and > 0">
                                    < 350 USD and> 0
                                </el-option>
                                <el-option value="Free">Free</el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="School's Student Size" prop="student_size">
                            <el-select v-model="form.student_size" placeholder="Please select" style="width: 100%;">
                                <el-option value="0-50 students" label="0-50 students"></el-option>
                                <el-option value="50-100 students" label="50-100 students"></el-option>
                                <el-option value="100-200 students" label="100-200 students"></el-option>
                                <el-option value="200-500 students" label="200-500 students"></el-option>
                                <el-option value="500-1000 students" label="500-1000 students"></el-option>
                                <el-option value="1000-2000 students" label="1000-2000 students"></el-option>
                                <el-option value="above 2000 students" label="Above 2000 students"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>



                <div class="row">
                    <div class="item">
                        <el-form-item label="Country/Region" prop="account_country">
                            <el-select v-model="form.account_country" style="width: 100%;" placeholder="Please select" filterable>
                                <el-option v-for="(country, index) in countryList" :key="index"
                                    :label="country.country_en" :value="country.country_en"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="State/Province" prop="account_province">
                            <el-input v-model="form.account_province" placeholder="Please input"></el-input>
                        </el-form-item>
                    </div>
                </div>

                <el-form-item label=" Address" prop="account_address">
                    <el-input v-model="form.account_address" placeholder="Please input"></el-input>
                </el-form-item>

                <p style="text-align: left;color: #D3A12A; font-size: 16px; font-weight: bold;margin: 20px 0px;">
                    Advisor Information</p>

                <el-form-item label="Will you be the advisor for the WEC Program in your school/institution?" prop="contact_as_adviser">
                    <el-radio v-model="form.contact_as_adviser" label="YES"></el-radio>
                    <el-radio v-model="form.contact_as_adviser" label="NO" style="margin-left: 100px;"></el-radio>
                </el-form-item>

                <template v-if="form.contact_as_adviser === 'NO'">
                    <p style="color: #666; font-size: 14px; line-height: 20px; margin-bottom: 20px;">If you are not the advisor for WEC Program, please provide the information of the right advisor.</p>
                    <div class="row">
                        <div class="item">
                            <el-form-item label="Advisor's First Name" style="flex: 1;" prop="adviser_givenName">
                                <el-input v-model="form.adviser_givenName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item label="Advisor's Last Name" style="flex: 1;" prop="adviser_lastName">
                                <el-input v-model="form.adviser_lastName" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="item">
                            <el-form-item label="Advisor's Position/Subject" style="flex: 1;" prop="adviser_subject">
                                <el-input v-model="form.adviser_subject" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item label="Advisor's Email" style="flex: 1;" prop="adviser_email">
                                <el-input v-model="form.adviser_email" placeholder="Please input"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </template>
            </el-form>

            <div class="terms flex items-center justify-center" style="margin-top: 20px;">
                <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                        :src="require('../../assets/image/unchecked.png')" />
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                        :src="require('../../assets/image/checked.png')" />
                </div>
                <p style="line-height: 20px;"> I agree that EAE may use my provided information to set up a personal
                    account and <br> to email me about notifications, reminders, and extracurricular opportunities.</p>
            </div>

            <div v-if="project.deadline_date && project.deadline_date < (new Date().getTime())" style="color: #D3A12A; font-size: 16px; font-weight: bold;margin: 20px 0px;text-align: center;">
                The registration deadline has passed.<br>
                <span style="font-size: 12px;color: #666;">The registration deadline is {{project.deadline_string}} in your local time.</span>
            </div>
            <div v-else class="submit">
                <div class="submitBtn" @click="submit">Submit</div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getCountries,
    saveAccountFormV1,
    postRequest
} from '../../api/eae'
import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            project: {},
            isAgree: false,

            countryList: [],

            currentYear: '',
            form: {
                BU: "WEC",
                project_code: "2025WEC",
                new_field: 'yes',
                account_country: '',
                account_name: '',
                account_nameEn: '',
                account_province: '',
                account_address: '',
                organization_type: '',

                curriculum_types: [],
                other_curriculum_type: '',
                annual_tuition: '',
                student_size: '',

                contact_lastName: '',
                contact_givenName: '',
                contact_prefix: '',
                contact_email: '',
                contact_position: '',

                know_sources: [],
                contact_as_adviser: '',

                adviser_lastName: '',
                adviser_givenName: '',
                adviser_subject: '',
                adviser_email: '',
            },
            formRules: {
                annual_tuition: [{ required: true, message: "Please select", trigger: "blur" }],
                curriculum_types: [
                    { required: true, message: "Please select", trigger: "blur" },
                ],
                know_sources: [{ required: true, message: "Please select", trigger: "blur" }],
                contact_lastName: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_givenName: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_prefix: [{ required: true, message: "Please select", trigger: "blur" }],
                contact_email: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_position: [{ required: true, message: "Please input", trigger: "blur" }],

                account_nameEn: [{ required: true, message: "Please input", trigger: "blur" }],
                organization_type: [{ required: true, message: "Please select", trigger: "blur" }],
                account_country: [{ required: true, message: "Please select", trigger: "blur" }],
                account_province: [{ required: true, message: "Please input", trigger: "blur" }],

                contact_as_adviser: [{ required: true, message: "Please select", trigger: "blur" }],
                adviser_givenName: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                adviser_lastName: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                adviser_subject: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                adviser_email: [{ 
                    required: true, 
                    message: "Please input", 
                    trigger: "blur",
                    validator: (rule, value, callback) => {
                        if (this.form.contact_as_adviser === 'NO' && !value) {
                            callback(new Error('Please input'));
                        } else {
                            callback();
                        }
                    }
                }],
                student_size: [
                    { required: true, message: "Please select", trigger: "change" }
                ],
            }
        }
    },
    mounted() {
        let date = new Date()
        let year = date.getFullYear()
        let currentMonth = date.getMonth() + 1
        if (currentMonth >= 9) {
            this.currentYear = year
        } else {
            this.currentYear = year - 1
        }
        this.getProjectInfo()
        this.requestCounties()
    },
    methods: {

        getProjectInfo(){
            postRequest({
                function: 'getProjectInfoByCode',
                project_code: this.form.project_code
            }).then((res) => {
                this.project = res.data
                // Add 23:59:59 to deadline date
                let deadlineDate =  new Date(this.project.deadline_date) 
                
                deadlineDate.setHours(23, 59, 59)
                
                
                // Convert to UTC+8 time string
                let utc8Date = new Date(deadlineDate.getTime())
                let utc8String = utc8Date.toLocaleString('en-US', { timeZone: 'Asia/Shanghai' })
                console.log('3'+utc8String)
                
                this.project.deadline_string = utc8String
                // Convert UTC+8 string to local browser time
                this.project.deadline_date = new Date(utc8String).getTime()

                console.log('4'+this.project.deadline_date)
                console.log('5'+new Date().getTime())
            })
        },

        checkShowOthersView(){
            for(let i=0;i<this.form.curriculum_types.length;i++){
                if(this.form.curriculum_types[i] == 'Others'){
                    return true
                }
            }
            return false
        },

        requestCounties() {
            getCountries().then((res) => {
                this.countryList = res.data
            })
        },

        submit() {
            if (!this.isAgree) {
                this.$message.warning("You must agree to the statement.");
                return;
            }

            this.$confirm(
                'Please confirm that you are registering as an advisor/teacher for your organization, NOT as a student. Students should use the Student Registration Form instead.',
                'Confirmation Required',
                {
                    confirmButtonText: 'Yes, I am an advisor/teacher',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            ).then(() => {
                this.$refs.form.validate((val) => {
                    if (val) {
                        saveAccountFormV1(this.form).then((res) => {
                            if (res) {
                                this.$confirm("Registration received. We'll first manually validate your organization information and then set up your school and advisor accounts. You will receive an email from us within 3-5 business days with further instructions on how to log into our EAE system, enter students (with your organization's exclusive student registration link) and form teams.", 'Registration Successful', {
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                    type: 'success'
                                })
                            }
                        });
                    }
                });
            }).catch(() => {
                // User cancelled the confirmation
            });
        }
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.accountform {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: white;
    width: 1000px;
    height: 100vh;
    overflow-y: auto;
    box-shadow: 0 2px 5px 1px rgba(124, 124, 124, 0.2);
    position: relative;

    .title1 {
        margin-top: 30px;
        font-family: GravityBold;
        color: #D3A12A;
        font-size: 22px;
        align-items: center;
        width: 100%;
        font-weight: bold;
        text-align: center;
    }

    .row {
        display: flex;
        justify-content: space-between;

        .item {
            width: 420px;
            margin-left: 20px;

            .label {
                font-size: 16px;
                font-family: GravityNormal;
                font-weight: normal;
                color: #666666;
            }

            .input {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 18px;

                font-family: GravityNormal;
                font-weight: normal;
                color: #333333;

                img {
                    margin-right: 24px;
                    cursor: pointer;
                }
            }
        }
    }

    .el-checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .el-radio-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .submit {
        .submitBtn {
            font-family: GravityBold;
            width: 280px;
            height: 50px;
            background: #D3A12A;
            border-radius: 25px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
            margin: 0 auto;
            margin-top: 20px;
            cursor: pointer;
        }

        margin-bottom: 50px;
    }
}


/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-color: #D3A12A;
}

/deep/ .el-input__inner:hover {
    border-color: #D3A12A;
}

/deep/ .el-input__inner:focus {
    border-color: #D3A12A;
}

/deep/ .el-form-item__label {
    font-size: 16px;
    color: #000000;
}

/deep/ .el-checkbox__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-radio__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #D3A12A;
    border-color: #D3A12A;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #D3A12A;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background-color: #D3A12A;
    border-color: #D3A12A;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #D3A12A;
}
</style>